<script>
import DiseasesNames from '@/components/Spraying/DiseasesNames.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import Loader from '@/components/Loaders/Loader.vue'
import ListItem from '@/pages/programas/ListItem.vue'
import ModalPrograma from '@/pages/programas/ModalPrograma.vue'
import ModalVideo from '@/components/ModalVideo.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProgramasList',

  mixins: [PermissionMixin, LayoutMixin],

  components: {
    ListItem,
    ModalPrograma,
    ModalVideo,
    DiseasesNames,
    Loader,
  },

  props: {
    programas: {
      required: true,
      type: Array,
    },
    cultivar: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      urlAjuda: 'https://www.youtube.com/embed/G5Moxp805fs',
      cloningProcess: false,
    }
  },

  mounted() {
    this.checkNavBtnStatus()
    this.checkIfHasCreatePermission()
  },

  watch: {
    programas: {
      async handler(programsObserver) {
        // XXX: force conversion to array because observers are not always an array
        // ref: https://guillim.github.io/vue/2019/03/20/damn-vuejs-observer.html
        const programs = Object.values(programsObserver)
        this.verifyProgramsWithWhiteMold(programs)

        if (programs?.length) {
          await this.fetchDefensivesLastData()
          const firstId = programs[0]?.id

          if (firstId) {
            await this.fetchDefensives({
              farmId: parseInt(this.currentFarmId),
              programId: firstId,
            })
          }
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId', 'enabledFarm']),
    ...mapGetters('user', ['isUSA']),
    canAddProgram() {
      return this.hasPermission(this.$p.planning.create)
    },
    calculaMaxAplicacoes() {
      if (!Array.isArray(this.programas) || !this.programas.length) {
        return 0
      }
      const totais_aplicacoes = this.programas.map(
        (programa) => programa?.planejamentos?.length
      )
      return Math.max(0, ...totais_aplicacoes)
    },
    firstProgram() {
      if (!this.programas?.length) {
        return null
      }
      return this.programas[0]?.id
    },
    isActivatedComparison() {
      return Object.keys(this.programas).length > 0 && this.enabledFarm
    },
    tutorial_toggle() {
      return process.env.VUE_APP_TUTORIAL_ENABLED === 'true' && !this.isUSA
    },
  },

  methods: {
    ...mapActions('defensives', ['fetchDefensives', 'fetchDefensivesLastData']),
    ...mapActions('program', ['verifyProgramsWithWhiteMold']),
    checkIfHasCreatePermission() {
      if (!this.canAddProgram) {
        this.$root.$emit(
          'notify',
          'warning',
          this.$t('programa.permissions.cant_create')
        )
      }
    },
    listaProgramas(programa) {
      this.$emit('alterou', programa.cultivar_id)
    },
    checkNavBtnStatus() {
      this.previousButton = document.querySelector('.v-slide-group__prev')
      this.nextButton = document.querySelector('.v-slide-group__next')
    },
    clickLeft() {
      this.previousButton.click()
      this.checkNavBtnStatus()
    },
    clickRight() {
      this.nextButton.click()
      this.checkNavBtnStatus()
    },
    comparisonRoute() {
      this.$router.replace({
        path: 'comparacao',
        query: {
          fazenda_id: this.currentFarmId,
          programa_id: this.firstProgram,
          cultivar_id: this.cultivar?.id,
        },
      })
    },
    duplicateProgram(program) {
      this.cloningProcess = true
      this.$api.programas
        .clonarParaCultivares(
          program.id,
          [program.cultivar_id],
          this.currentFarmId
        )
        .then(() => {
          this.$emit('alterou', program.cultivar_id)
        })
        .catch((err) => {
          const message =
            err.response.data.message || this.$t('planejamento.salvar.erro_400')
          this.$root.$emit('notify', 'error', message)
        })
        .finally(() => (this.cloningProcess = false))
    },
  },
}
</script>

<template>
  <div>
    <v-row v-if="isMobile" align="center" no-gutters>
      <v-col class="title py-1" cols="2">
        {{ $t('programa.programas') }}
      </v-col>
      <v-col cols="4" class="pa-1 text-left" v-if="enabledFarm">
        <v-btn
          v-if="isActivatedComparison"
          x-small
          color="primary"
          @click="comparisonRoute"
        >
          <v-icon>mdi-compare-horizontal</v-icon> &nbsp;
          {{ $t('Comparison.title') }}
        </v-btn>
      </v-col>
      <v-col class="pa-1 text-right" cols="3" v-if="tutorial_toggle">
        <modal-video class="pl-6 pb-2" :url-video="urlAjuda" />
      </v-col>
    </v-row>
    <v-row v-else align="center" no-gutters>
      <div class="list-header">
        <div class="list-header-title">
          <div>{{ $t('programa.programas') }}</div>
          <modal-video
            v-if="tutorial_toggle"
            :url-video="urlAjuda"
            event="tutorial_programa"
            name="list_programa"
          />
          <v-col class="pa-1 d-md-none" cols="6">
            <modal-video
              v-if="tutorial_toggle"
              :url-video="urlAjuda"
              event="tutorial_programa"
              name="list_programa"
            />
          </v-col>
        </div>
        <v-btn
          v-if="isActivatedComparison"
          class="btn-compare"
          @click="comparisonRoute"
        >
          {{ $t('Comparison.title') }}
          <v-icon color="#39AF49">mdi-compare-horizontal</v-icon>
        </v-btn>
      </div>
      <v-col class="pa-1 d-flex d-md-none" cols="4">
        {{ $t('programa.programas') }}
      </v-col>
      <v-col cols="8" class="pa-1 text-right d-md-none">
        <modal-programa :cultivar="cultivar" @salva="listaProgramas">
          <template #activator="{ on }">
            <v-btn
              depressed
              class="white--text"
              color="#8BC34A"
              v-on="on"
              x-small
            >
              {{ $t('adicionar') }} {{ $t('programa.programa') }}
            </v-btn>
          </template>
        </modal-programa>
      </v-col>
    </v-row>
    <v-row class="d-sm-none">
      <v-col class="text-left">
        <v-btn
          fab
          dark
          color="blue-grey darken-3 mr-2"
          height="40px"
          width="40px"
          @click="clickLeft"
        >
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          fab
          dark
          color="blue-grey darken-3 ml-2"
          height="40px"
          width="40px"
          @click="clickRight"
        >
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-slide-group class="mt-4" mobile-breakpoint="xs">
      <template #prev>
        <v-row align="start" style="height: 100%">
          <v-row align="center" justify="end" style="height: 500px" no-gutters>
            <v-col class="text-center">
              <v-btn
                fab
                dark
                color="blue-grey darken-3 mr-2"
                height="40px"
                width="40px"
              >
                <v-icon dark>mdi-chevron-left</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </template>
      <template #next>
        <v-row class="align-start" style="height: 100%">
          <v-row align="center" justify="end" style="height: 500px" no-gutters>
            <v-col class="text-center">
              <v-btn
                fab
                dark
                color="blue-grey darken-3 ml-2"
                height="40px"
                width="40px"
              >
                <v-icon dark>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </template>
      <v-slide-item
        class="qa-program-first-column"
        v-if="!$vuetify.breakpoint.xs"
      >
        <div style="max-width: 100px; float: right">
          <div style="height: 150px"></div>

          <div v-for="i in calculaMaxAplicacoes" :key="i">
            <v-card
              tile
              class="grey darken-3 white--text mt-15 text-center"
              elevation="0"
              height="156px"
            >
              <v-card-text
                class="float-center text-center white--text pa-2"
                style="height: 100%"
              >
                <div class="title" style="line-height: 130px">{{ i }}ª</div>
              </v-card-text>
            </v-card>

            <div style="height: 294px; padding-top: 50px">
              <diseases-names class="program-diseases"></diseases-names>
            </div>
          </div>
        </div>
      </v-slide-item>
      <v-dialog v-model="cloningProcess" width="auto" persistent>
        <v-card width="340">
          <Loader style="display: contents" />
        </v-card>
      </v-dialog>
      <v-slide-item
        class="mx-2 v-slide-item--custom"
        v-for="programa in programas"
        :key="programa.id"
      >
        <list-item
          :cloning-process="cloningProcess"
          :program="programa"
          :cultivar="cultivar"
          @alterou="listaProgramas"
          @duplicate="duplicateProgram"
        >
        </list-item>
      </v-slide-item>
      <v-slide-item
        class="mx-2 v-slide-item--custom"
        v-if="canAddProgram && enabledFarm"
      >
        <v-row class="d-inline-flex ma-2" align="center" style="height: 150px">
          <modal-programa :cultivar="cultivar" @salva="listaProgramas">
            <template #activator="{ on }">
              <v-btn
                data-id="add-button"
                fab
                dark
                color="blue-grey darken-3"
                height="50px"
                width="50px"
                v-on="on"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
              <div>
                <p class="text--secondary caption ma-2 text-left">
                  {{ $t('adicionar') }}<br />{{ $t('programa.programa') }}
                </p>
              </div>
            </template>
          </modal-programa>
        </v-row>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<style scoped>
::v-deep .v-sheet.v-card {
  border-radius: 8px !important;
}
.program-diseases {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: #f8f9f8;
}

.v-slide-item--custom {
  max-width: 260px;
}

.list-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.list-header-title {
  display: flex;
  gap: 10px;
  font-family: 'Rubik';
  font-weight: 600;
  font-size: 14px;
}

.btn-compare {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 14px;
  color: #3f4e3c;
  background: #ffffff;
  border: 1px solid #e6e9e6;
  box-shadow: 0px 2px 1px rgba(11, 47, 16, 0.12);
  border-radius: 4px;
}

@media screen and (max-width: 600px) {
  ::v-deep .v-application .justify-sm-end {
    justify-content: flex-start !important;
  }
  .v-slide-item--custom {
    max-width: 240px;
  }
}
</style>
