<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    cultivar: { default: () => {}, type: Object },
    programa: { default: () => {}, type: Object },
  },

  data() {
    return {
      dialog: null,
      saving: false,
      cultivaresSelecionadas: [],
      fazendas: [],
    }
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
  },

  methods: {
    marcarTodas() {
      const getCultivares = (f) => f.cultivares
      const flatten = (acumulador, cultivares) => acumulador.concat(cultivares)
      this.cultivaresSelecionadas = this.fazendas
        .map(getCultivares)
        .reduce(flatten, [])
    },

    desmarcarTodas() {
      this.cultivaresSelecionadas = []
    },

    desmarcarFazenda(fazendaId) {
      this.cultivaresSelecionadas = this.cultivaresSelecionadas.filter(
        (c) => c.fazenda_id != fazendaId
      )
    },

    fechar() {
      this.dialog = false

      // FIXME: forçar recarga para não ter lixo caso reabra a tela
    },

    checkCropType(crop) {
      return crop.semente.crop_id == this.cultivar.crop_id
    },

    salvar() {
      this.saving = true

      const cultivaresIds = this.cultivaresSelecionadas.map((c) => c.id)

      this.$api.programas
        .clonarParaCultivares(
          this.programa.id,
          cultivaresIds,
          this.currentFarmId
        )
        .then((response) => {
          this.dialog = false
          this.saving = false

          // assegura que form não guarda lixo se estiver criado um programa
          if (!this.isEditing) this.desmarcarTodas()

          this.$emit('clonou', response.data)
        })
        .catch((err) => {
          this.saving = false

          const message =
            err.response.data.message || this.$t('planejamento.salvar.erro_400')
          this.$root.$emit('notify', 'error', message)
        })
    },
  },

  mounted() {
    this.$api.fazendas
      .allWithCultivares()
      .then((response) => {
        this.fazendas = response.data
      })
      .catch((err) => {
        this.saving = false

        const message =
          err.response.data.message || this.$t('planejamento.salvar.erro_400')
        this.$root.$emit('notify', 'error', message)
      })
  },
}
</script>

<template>
  <v-dialog v-model="dialog" :persistent="saving" width="700">
    <template #activator="{ on }">
      <slot name="activator" :on="on" />
    </template>

    <v-card>
      <v-card-title
        class="headline blue-grey darken-2 white--text"
        primary-title
      >
        <v-icon class="white--text pa-2">mdi-content-copy</v-icon>
        <span>{{ $t('programa.copiar_para.titulo') }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <v-icon class="white--text">mdi-help-circle-outline</v-icon>
            </div>
          </template>

          <div>
            {{ $t('programa.copiar_para.help') }}
          </div>
        </v-tooltip>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <span class="pr-4">
              {{ $t('programa.copiar_para.instrucao') }}
              <strong>{{ programa.nome }}</strong>
            </span>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          align="center"
          dense
          v-for="fazenda in fazendas"
          :key="'fazenda_' + fazenda.id"
        >
          <v-col> </v-col>
          <v-col cols="3">
            <div class="title">{{ fazenda.nome }}</div>
          </v-col>

          <v-col cols="6" align="center">
            <v-select
              v-model="cultivaresSelecionadas"
              :items="fazenda.cultivares.filter(checkCropType)"
              item-text="semente.cultivar"
              item-value="id"
              :label="$t('programa.copiar_para.selecione_cultivares')"
              :disabled="saving"
              multiple
              chips
              return-object
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-btn
              color="red"
              fab
              x-small
              dark
              @click="desmarcarFazenda(fazenda.id)"
              :disabled="saving"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          @click="marcarTodas"
          :disabled="saving"
        >
          {{ $t('programa.copiar_para.marcar_todas') }}
        </v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="desmarcarTodas"
          :disabled="saving"
        >
          {{ $t('limpar') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="fechar" :disabled="saving">
          {{ $t('cancelar') }}
        </v-btn>
        <v-btn color="primary" @click="salvar" :loading="saving">
          {{ $t('copiar') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
