import { render, staticRenderFns } from "./DiseaseHeader.vue?vue&type=template&id=36df9199&scoped=true"
import script from "./DiseaseHeader.vue?vue&type=script&lang=js"
export * from "./DiseaseHeader.vue?vue&type=script&lang=js"
import style0 from "./DiseaseHeader.vue?vue&type=style&index=0&id=36df9199&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36df9199",
  null
  
)

export default component.exports