<template>
  <div class="program-header">
    <v-card class="card crop">
      <v-select
        style="background: white"
        :value="currentCrop"
        :items="translatedCrops"
        item-value="id"
        item-text="name"
        class="crop-selector"
        single-line
        outlined
        color="#39af49"
        :label="$t('disease_control.select_crop')"
        @change="changeCrop"
      >
      </v-select>
    </v-card>
    <v-card class="card variety">
      <v-select
        style="background: white"
        v-model="cultivar"
        :items="varietiesAndCropHeaders"
        item-value="id"
        item-text="cultivar"
        class="season-selector"
        single-line
        outlined
        color="#39af49"
        :label="$t('disease_control.select_variety')"
        return-object
      >
        <template #append-item>
          <div class="px-2">
            <v-btn class="create-variety" @click="createVariety">
              {{ $t('disease_control.create_variety') }}
              <font-awesome-icon icon="leaf" class="ml-1" color="#39AF49" />
            </v-btn>
          </div>
        </template>
      </v-select>
    </v-card>
    <v-card class="card data">
      <div>
        <div class="type">
          <v-icon size="14" color="#AAB2A9">mdi-office-building-outline</v-icon>
          {{ $t('variety.owner') }}
        </div>
        <div v-if="cultivar" class="variety-info">
          {{ cultivar.titular }}
        </div>
      </div>
      <div
        v-if="
          cultivar && cultivar.semeadura_ini && cultivar.semeadura_fim && !isUSA
        "
      >
        <div class="type">
          <v-icon size="14" color="#AAB2A9">mdi-calendar</v-icon>
          {{ $t('cultivar.epoca_semeadura') }}
        </div>
        <div class="variety-info">
          {{ $t('_to_', [plantingTimeStart, plantingTimeEnd]) }}
        </div>
      </div>
      <div v-if="cultivar && cultivar.habito && !isUSA">
        <div class="type">
          <v-icon size="14" color="#AAB2A9"
            >mdi-chart-areaspline-variant</v-icon
          >
          {{ $t('cultivar.habito_crescimento') }}
        </div>
        <div class="variety-info">{{ cultivar.habito }}</div>
      </div>
      <div v-if="cultivar && cultivar.ciclo_dias">
        <div class="type">
          <v-icon size="14" color="#AAB2A9"
            >mdi-chart-areaspline-variant</v-icon
          >
          {{ $t('cultivar.ciclo') }}
        </div>
        <div class="variety-info">
          {{ varietyStartCycle }} {{ $t('until') }} {{ varietyEndCycle }}
          {{ $t('days') }}
        </div>
      </div>
      <div>
        <variety-info-modal :variety="cultivar" />
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import VarietyInfoModal from '@/pages/cultivares/VarietyInfoModal.vue'
import { getCropNameById } from '@/utils/crops.js'
import LayoutMixin from '@/components/LayoutMixin.vue'

export default {
  name: 'ProgramHeader',

  mixins: [PermissionMixin, LayoutMixin],

  props: {
    cultivares: { type: Array, required: true },
  },

  data() {
    return {
      cultivar: {},
    }
  },

  components: {
    VarietyInfoModal,
  },

  watch: {
    cultivares() {
      const varietyId = this.cultivar?.id ?? this.$route.query.cultivar_id
      this.cultivar =
        this.varietiesByCrop.find((c) => c.id == varietyId) ??
        this.varietiesByCrop[0]
    },
    cultivar() {
      this.changeVariety()
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('user', ['isUSA']),
    ...mapGetters('seasonsCrops', ['enabledCrops']),
    ...mapGetters('crops', ['currentCrop']),
    translatedCrops() {
      return this.enabledCrops.map((crop) => {
        return {
          ...crop,
          name: this.$t('crop.' + crop.name.toLowerCase()),
        }
      })
    },
    varietiesByCrop() {
      return this.cultivares.filter(
        ({ crop_id }) => crop_id == this.currentCrop.id
      )
    },
    varietiesAndCropHeaders() {
      return this.varietiesByCrop.reduce((acc, variety) => {
        const currentCropId = variety.crop_id
        const hasCrop = acc.some(({ crop_id }) => crop_id == currentCropId)
        if (!hasCrop) {
          const cropName = getCropNameById(currentCropId)
          acc.push({ header: this.$t(cropName) })
        }
        acc.push(variety)
        return acc
      }, [])
    },
    plantingTimeStart() {
      return this.cultivar.semeadura_ini
        ? this.cultivar.semeadura_ini.formatDate(this.$t('date_without_year'))
        : null
    },
    plantingTimeEnd() {
      return this.cultivar.semeadura_fim
        ? this.cultivar.semeadura_fim.formatDate(this.$t('date_without_year'))
        : null
    },
    varietyStartCycle() {
      return (
        parseInt(this.cultivar.ciclo_dias) -
        parseInt(process.env.VUE_APP_CICLO_PERIODO_TOLERANCIA)
      )
    },
    varietyEndCycle() {
      return (
        parseInt(this.cultivar.ciclo_dias) +
        parseInt(process.env.VUE_APP_CICLO_PERIODO_TOLERANCIA)
      )
    },
  },

  methods: {
    ...mapActions('crops', ['setCurrentCrop']),
    changeCrop(cropId) {
      this.setCurrentCrop(cropId)
      this.changeVariety()
    },
    changeVariety() {
      if (this.cultivar) {
        this.$router.replace({
          query: {
            cultivar_id: this.cultivar.id,
            fazenda_id: this.currentFarmId,
          },
        })
      } else {
        this.$router.replace({
          query: {
            fazenda_id: this.currentFarmId,
          },
        })
      }
      this.$emit('change-variety', this.cultivar)
    },
    createVariety() {
      this.$router.replace({
        path: 'cultivares',
        query: { fazenda_id: this.currentFarmId },
      })
    },
  },
}
</script>

<style scoped>
.program-header {
  display: flex;
  flex-direction: row;
  gap: 18px;
  width: 100%;
}
::v-deep .v-select__slot,
::v-deep .v-input__control {
  height: 70px;
}
::v-deep .v-input__icon {
  height: 50px;
}
::v-deep .v-input__slot {
  margin: 0px;
}
::v-deep .v-select__selections {
  font-family: 'Rubik';
  font-weight: 600;
  font-size: 16px;
  color: #1a2b46;
}
::v-deep .v-list-item__content {
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 12px;
  color: #51594f;
}
::v-deep fieldset {
  border: none;
}
.card {
  background: #ffffff;
  box-shadow: none !important;
  border: 1px solid #e6e9e6;
  border-radius: 8px;
  height: fit-content;
}
.card.data {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 8px;
}
.card.data .type {
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #aab2a9;
}
.card.data .variety-info {
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 14px;
  color: #5b6459;
}
.card.data .crop {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.create-variety {
  background: #ffffff;
  border: 1px solid #e6e9e6;
  border-radius: 4px;
  padding: 8px;
  width: 100%;
}
.create-variety p {
  font-family: 'Rubik';
  font-weight: 700;
  font-size: 14px;
  color: #3f4e3c;
}
@media (max-width: 768px) {
  .card.data {
    display: none;
  }
  ::v-deep .v-input__control {
    height: 35px;
  }
  ::v-deep .v-input__icon {
    height: 25px;
  }
  ::v-deep .v-select__slot {
    height: 50px;
  }
}
</style>
