<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import DRadioField from '@/components/Form/DRadioField'
import { currentLocale } from '@/language'

export default {
  name: 'ModalPrograma',
  components: { DRadioField },
  props: {
    cultivar: { type: Object, required: true },
    programa: { type: Object, required: false, default: null },
  },

  data() {
    return {
      dialog: null,
      dataSemeadura: moment().toDate().toISOString().substr(0, 10),
      white_mold_enabled: false,
      nome: null,
      severidade: 1, // alta por padrão
      severidades: [this.$t('alta'), this.$t('media'), this.$t('baixa')],
      doencasSugeridas: true,
      minDate: null,
      maxDate: null,
      valid: true,
      nomeRules: [
        (v) => !!v || this.$t('campo_obrigatorio'),
        (v) =>
          (!!v && v.length <= this.nomeMaxChar) || this.$t('nome_muito_longo'),
      ],
      nomeMaxChar: 20,
      isEditing: null,
      vPrograma: null,
      currentStep: 1,
      totalSteps: 4,
      saving: false,
    }
  },

  computed: {
    getLocale() {
      return currentLocale()
    },

    isRecommendedDate() {
      return !this.notRecommendedDates(this.dataSemeadura)
    },
    ...mapGetters('crops', ['isSoybean']),
    ...mapGetters('farms', ['isFarmFromUSA']),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),
  },

  watch: {
    dialog(val) {
      this.isEditing = !!this.programa

      if (val) {
        if (this.cultivar) {
          this.vPrograma = this.programa || { cultivar_id: this.cultivar.id }

          const tolerancia = process.env.VUE_APP_DATA_SEMEADURA_TOLERANCIA

          if (this.isSoybean) {
            this.minDate = moment(this.currentSeasonCrop?.start_period)
            // XXX - é removido os dias de tolerancia para que a data de emergencia fique dentro do limite
            this.maxDate = moment(this.currentSeasonCrop?.end_period).subtract(
              tolerancia,
              'days'
            )
          } else {
            this.setWheatSowingTime()
          }

          this.dataSemeadura = moment(this.minDate, 'YYYY-MM-DD hh:mm:ss')
            .toDate()
            .toISOString()
            .substr(0, 10)
        }

        if (this.programa) {
          this.nome = this.programa.nome

          this.dataSemeadura = moment(
            this.programa.data_prevista,
            'YYYY-MM-DD hh:mm:ss'
          )
            .toDate()
            .toISOString()
            .substr(0, 10)

          this.severidade = this.programa.severidade_id || 1
          this.doencasSugeridas = this.programa.doencas_sugeridas
          this.white_mold_enabled = this.programa.white_mold_enabled
        }

        this.currentStep = 1
      }
    },
  },

  methods: {
    setWheatSowingTime() {
      const tolerancia = process.env.VUE_APP_DATA_SEMEADURA_TOLERANCIA

      this.minDate = moment(this.currentSeasonCrop?.start_period)
      this.maxDate = moment(this.currentSeasonCrop?.end_period).subtract(
        tolerancia,
        'days'
      )
    },
    prevStep() {
      if (this.currentStep > 1) this.currentStep -= 1
    },

    nextStep() {
      if (this.currentStep < this.totalSteps) this.currentStep += 1
    },

    allowedDates(date) {
      return moment(date).isBetween(this.minDate, this.maxDate, null, '[]')
    },

    notRecommendedDates(date) {
      if (this.isSoybean && this.isFarmFromUSA) {
        return false
      }

      if (!this.isSoybean || !this.cultivar.recommended) {
        return false
      }

      const { semeadura_ini, semeadura_fim } = this.cultivar

      const moment_semeadura_ini = moment(semeadura_ini, 'YYYY-MM-DD')
      const moment_semeadura_fim = moment(semeadura_fim, 'YYYY-MM-DD')
      const isNotInRecommendedInterval = !moment(date).isBetween(
        moment_semeadura_ini,
        moment_semeadura_fim,
        null,
        '[]'
      )
      return isNotInRecommendedInterval && this.allowedDates(date)
    },

    formatDateISO(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    validate() {
      return this.$refs.form.validate()
    },

    resetAll() {
      this.nome = null
      this.dataSemeadura = null
      this.severidade = 1
    },

    salvar() {
      if (!this.valid || !this.validate()) {
        this.$root.$emit(
          'notify',
          'error',
          this.$t('preencha_corretamente_campos')
        )
        return
      }

      if (!this.allowedDates(this.dataSemeadura)) {
        this.$root.$emit(
          'notify',
          'error',
          this.$t('semeadura.data_fora_semeadura')
        )
        return
      }

      this.saving = true

      this.atualizaProgramaParaSalvar()

      const apiRequest = this.updateOrCreateRequest()

      apiRequest
        .then((response) => {
          this.dialog = false
          this.saving = false

          if (!this.isEditing) this.resetAll()

          this.$emit('salva', response.data)

          this.$root.$emit(
            'notify',
            'warning',
            this.$t('eficacia.eficacias_apresentadas')
          )
        })
        .catch((error) => {
          this.saving = false
          const message =
            error?.response?.data?.message || this.$t('programa.salva.erro_400')
          this.$root.$emit('notify', 'error', message)
        })
    },

    updateOrCreateRequest() {
      return this.isEditing
        ? this.$api.programas.update(this.vPrograma)
        : this.$api.programas.create(this.vPrograma)
    },

    atualizaProgramaParaSalvar() {
      this.vPrograma.white_mold_enabled = this.white_mold_enabled
      this.vPrograma.nome = this.nome
      this.vPrograma.data_prevista = moment(this.dataSemeadura).format(
        'YYYY-MM-DD HH:mm:ss'
      )
      this.vPrograma.severidade_id = this.severidade
      this.vPrograma.doencas_sugeridas = this.doencasSugeridas
    },
  },
}
</script>

<template>
  <v-dialog v-model="dialog" :persistent="saving" width="800">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <v-card v-if="vPrograma">
      <v-form ref="form" v-model="valid" @submit.prevent>
        <v-toolbar class="headline blue-grey darken-2 white--text">
          <span>{{ $t('programa.novo_programa') }}</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom class="pr-1">
            <template #activator="{ on }">
              <div v-on="on">
                <v-icon class="white--text">mdi-help-circle-outline</v-icon>
              </div>
            </template>
            <div>
              {{ $t('eficacia.ajuda') }}
            </div>
          </v-tooltip>
          <v-btn icon dark @click="dialog = false" right>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-stepper
          v-model="currentStep"
          :vertical="false"
          non-linear
          alt-labels
        >
          <v-stepper-header>
            <v-stepper-step step="1" :complete="currentStep > 1">
              <span class="caption">{{ $t('nome') }}</span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2" :complete="currentStep > 2">
              <span class="caption">{{
                $t('eficacia.data_prevista_semeadura')
              }}</span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3" :complete="currentStep > 3">
              <span class="caption">{{
                $t('eficacia.severidade_esperada')
              }}</span>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content key="name-content" step="1">
              <v-card-text>
                <v-text-field
                  v-model="nome"
                  :counter="nomeMaxChar"
                  :rules="nomeRules"
                  :label="$t('programa.nome_programa')"
                  required
                ></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey darken-1"
                  text
                  @click="dialog = false"
                  :disabled="saving"
                >
                  {{ $t('cancelar') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="!!nome && nextStep()"
                  :loading="saving"
                >
                  {{ $t('next') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content key="data-prevista-content" step="2">
              <v-container class="pr-0 pl-0 pl-sm-3 pr-sm-3">
                <v-row>
                  <v-col cols="12" class="subtitle text-center text-sm-left">
                    {{ $t('eficacia.escolha_data_prevista') }}
                  </v-col>
                  <v-col col="12">
                    <v-alert
                      type="warning"
                      dense
                      text
                      prominent
                      v-if="!this.isRecommendedDate"
                    >
                      {{
                        $t('eficacia.data_prevista_semeadura_nao_recomendada')
                      }}
                    </v-alert>
                  </v-col>
                  <v-col cols="12" class="pr-0 pl-0 pl-sm-3 pr-sm-3">
                    <v-date-picker
                      v-model="dataSemeadura"
                      show-current
                      landscape
                      :color="isRecommendedDate ? 'primary' : 'orange darken-2'"
                      header-color="primary"
                      :locale="getLocale"
                      :allowed-dates="allowedDates"
                      :events="notRecommendedDates"
                      :min="formatDateISO(minDate)"
                      :max="formatDateISO(maxDate)"
                      required
                      no-title
                      full-width
                      v-if="$vuetify.breakpoint.xs"
                    ></v-date-picker>
                    <v-date-picker
                      v-model="dataSemeadura"
                      show-current
                      landscape
                      :color="isRecommendedDate ? 'primary' : 'orange darken-2'"
                      header-color="primary"
                      :locale="getLocale"
                      :allowed-dates="allowedDates"
                      :events="notRecommendedDates"
                      :min="formatDateISO(minDate)"
                      :max="formatDateISO(maxDate)"
                      required
                      v-else
                    ></v-date-picker>
                  </v-col>
                </v-row>
              </v-container>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey darken-1"
                  text
                  @click="prevStep"
                  :disabled="saving"
                >
                  {{ $t('anterior') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="
                    !!dataSemeadura && allowedDates(dataSemeadura) && nextStep()
                  "
                  :loading="saving"
                >
                  {{ $t('next') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content key="severidade-content" step="3">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-radio-group v-model="severidade" column>
                      <template #label>
                        {{ $t('eficacia.escolha_severidade_esperada') }}
                      </template>
                      <v-radio
                        v-for="(sev, i) in severidades"
                        :key="`sev_${i + 1}`"
                        :label="sev"
                        color="red"
                        :value="i + 1"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    v-if="$toggle('white_mold_enabled') && isSoybean"
                  >
                    <d-radio-field
                      v-model="white_mold_enabled"
                      :title="$t('programa.white_mold_control_title') + '?'"
                      :description="
                        $t('programa.white_mold_control_description')
                      "
                      :disabled="isEditing"
                      row
                    ></d-radio-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="grey darken-1"
                  text
                  @click="prevStep"
                  :disabled="saving"
                >
                  {{ $t('anterior') }}
                </v-btn>

                <v-btn color="primary" @click="salvar" :loading="saving">
                  {{ $t('salvar') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-form>
    </v-card>
  </v-dialog>
</template>
