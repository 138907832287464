<template>
  <permission-failed-message v-if="!hasPermission($p.planning.list)" />
  <permission-limited-message v-else-if="!hasPermission($p.planning.edit)" />
  <div v-else>
    <disease-header />
    <div class="df-flex-l df-flex-col disease-home">
      <farm-disabled v-if="!farms.length" />
      <loader v-else-if="cultivaresLoading" />
      <programas-header
        v-show="!cultivaresLoading && varietiesLoaded"
        :cultivares="cultivares"
        @change-variety="changeVariety"
      />
      <without-varieties
        v-if="
          farms.length && !cultivaresLoading && (!cultivar || !varietiesLoaded)
        "
      />
      <loader v-if="loadingVariety" />
      <programas-list
        v-if="!loadingVariety && varietiesLoaded && cultivar"
        :programas="programas"
        :cultivar="cultivar"
        @alterou="fetchPrograms"
      />
    </div>
  </div>
</template>

<script>
import DiseaseHeader from '@/pages/programas/DiseaseHeader.vue'
import FarmDisabled from '@/components/Banners/FarmDisabled.vue'
import Loader from '@/components/Loaders/Loader.vue'
import ProgramasHeader from '@/pages/programas/ProgramHeader.vue'
import ProgramasList from '@/pages/programas/List.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import WithoutVarieties from '@/components/Banners/WithoutVarieties.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'DiseaseHome',

  mixins: [PermissionMixin],

  components: {
    DiseaseHeader,
    FarmDisabled,
    Loader,
    ProgramasHeader,
    ProgramasList,
    WithoutVarieties,
  },

  data() {
    return {
      loadingVariety: false,
      programas: [],
    }
  },

  computed: {
    ...mapGetters('farms', ['farms', 'currentFarmId']),
    ...mapGetters('cultivares', [
      'cultivares',
      'cultivar',
      'cultivaresLoading',
    ]),
    ...mapGetters('seasonsCrops', ['currentSeasonCrop']),
    varietiesLoaded() {
      return this.cultivares.length > 0
    },
  },

  methods: {
    ...mapMutations('cultivares', ['setCultivar']),
    async changeVariety(variety) {
      this.setCultivar(variety)
      if (variety) {
        await this.fetchPrograms(variety.id)
      } else {
        this.programas = []
      }
    },
    async fetchPrograms(cultivarId) {
      this.loadingVariety = true
      try {
        const response = await this.$api.programas.findByCultivar(
          cultivarId,
          this.currentSeasonCrop?.start_period,
          this.currentSeasonCrop?.end_period
        )
        this.programas = response.data
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingVariety = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.disease-home {
  max-width: 1472px;
  margin: 0px auto;
  padding: 0px 16px 40px 16px;

  @include d(m) {
    padding: 0px 8px 12px 8px;
  }
}
::v-deep .v-divider {
  opacity: 1;
  border: 1px solid #e6e9e6;
  margin: 20px 0px 0px 0px;
}
</style>
