<script>
import ModalPrograma from './ModalPrograma.vue'
import SprayingModal from '@/components/Spraying/SprayingModal.vue'
import ModalReplicacao from './ModalReplicacao.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import CropDiseasesMixin from '@/components/Spraying/CropDiseasesMixin.vue'
import Efficacies from '@/components/Efficacies'
import TotalSprayingsInfo from '@/components/Program/TotalSprayingsInfo.vue'
import { currentLanguage, LANG } from '@/language'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { events, logEvent } from '@/services/analytics'

export default {
  mixins: [PermissionMixin, CropDiseasesMixin],
  components: {
    ModalPrograma,
    SprayingModal,
    ModalReplicacao,
    Efficacies,
    TotalSprayingsInfo,
  },

  props: {
    program: {
      type: Object,
      required: true,
    },
    cultivar: {
      type: Object,
      required: true,
    },
    cloningProcess: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('crops', [
      'isSoybean',
      'maxSprayingsByCurrentCrop',
      'currentCrop',
    ]),
    ...mapGetters('farms', ['enabledFarm', 'currentFarm']),
    ...mapGetters('user', ['isUSA', 'isBrazil']),

    bagPrice() {
      const bag = this.currentFarm?.bag_price?.find(
        (bag) => bag.crop_id === this.currentCrop.id
      )
      return bag?.price ?? 0
    },

    custoTotalSc() {
      return (this.custoTotal() / this.bagPrice).toFixed(2)
    },

    novoPlanejamentoIndex() {
      return this.program.planejamentos.length + 1
    },

    showDefensiveMenu() {
      return (
        this.hasPermission(this.$p.defensive.edit) ||
        this.hasPermission(this.$p.defensive.delete)
      )
    },

    comparisonRoute() {
      return {
        path: 'comparacao',
        query: {
          fazenda_id: this.currentFarm?.id,
          programa_id: this.program?.id,
          cultivar_id: this.cultivar?.id,
        },
      }
    },

    allowNovaAplicacao() {
      return this.program?.planejamentos?.length < this.maxSpraying
    },

    maxSpraying() {
      return this.isUSA
        ? process.env.VUE_APP_USA_MAX_APLICACOES
        : this.maxSprayingsByCurrentCrop
    },
  },

  methods: {
    semRegistro(eficacias) {
      return eficacias.find(
        (e) => e.sem_registro && !this.ignoredDiseases?.includes(e.alvo_id)
      )
    },

    aplicacaoDataRange(planejamento) {
      const dateFormat = currentLanguage() === LANG.EN ? 'MM/DD' : 'DD/MM'
      const dataMin = moment(planejamento.data_aplicacao_planejada)
        .subtract(process.env.VUE_APP_DATA_APLICACAO_PERIODO_TOLERANCIA, 'days')
        .format(dateFormat)

      const dataMax = moment(planejamento.data_aplicacao_planejada)
        .add(process.env.VUE_APP_DATA_APLICACAO_PERIODO_TOLERANCIA, 'days')
        .format(dateFormat)

      return this.$t('_to_', [dataMin, dataMax])
    },

    custoTotal() {
      const precoFloat = (d) => (d.preco !== null ? d.preco * d.dose : 0)
      const pegaPrecos = (p) => p.planejamento_defensivos.map(precoFloat)
      const flatten = (acumulador, precos) => acumulador.concat(precos)
      const somaTudo = (preco1, preco2) => preco1 + preco2
      return this.program.planejamentos
        .map(pegaPrecos)
        .reduce(flatten, [])
        .reduce(somaTudo, 0)
        .toFixed(2)
    },

    custoPlanejamento(planejamento) {
      const precoFloat = (d) => (d.preco !== null ? d.preco * d.dose : 0)
      const somaTudo = (preco1, preco2) => preco1 + preco2
      return planejamento.planejamento_defensivos
        .map(precoFloat)
        .reduce(somaTudo, 0)
        .toFixed(2)
    },

    custoPlanejamentoSc(planejamento) {
      return (this.custoPlanejamento(planejamento) / this.bagPrice).toFixed(2)
    },

    salvarPrograma() {
      this.$emit('alterou', this.program)
    },

    clonarPrograma() {
      this.$emit('alterou', this.program)
    },
    duplicateProgram() {
      this.$emit('duplicate', this.program)
    },
    excluirPrograma() {
      this.$api.programas
        .delete(this.program.id)
        .then(() => {
          this.$root.$emit(
            'notify',
            'success',
            this.$t('programa.excluir.sucesso')
          )
          this.$emit('alterou', this.program)
        })
        .catch((err) => {
          const message = err.response.data.message
            ? err.response.data.message
            : this.$t('programa.excluir.erro_400')
          this.$root.$emit('notify', 'error', message)
        })
    },

    salvarPlanejamento() {
      this.$emit('alterou', this.program)
    },

    excluirPlanejamento(planejamento) {
      this.$api.programas.planejamentos
        .delete(planejamento)
        .then(() => {
          this.$root.$emit(
            'notify',
            'success',
            this.$t('planejamento.succesfuly_removed')
          )
          this.$emit('alterou', this.program)
        })
        .catch((err) => {
          const message = err.response.data.message
            ? err.response.data.message
            : this.$t('planejamento.erro.erro_400')
          this.$root.$emit('notify', 'error', message)
        })
    },

    canDeleteSpray(index) {
      const isLastSpray = index == this.program.planejamentos.length - 1
      return this.hasPermission(this.$p.defensive.delete) && isLastSpray
    },

    specificWhiteMoldSpraying(planning) {
      return planning.doenca_prioritaria_id == 13
    },

    handleButtonClick() {
      logEvent(events.programs.clickedButton_addSpraying)
    },
  },
}
</script>

<template>
  <v-slide-item v-slot="{ toggle }">
    <div>
      <v-card
        tile
        elevation="0"
        class="grey darken-1 center white--text mb-4"
        height="150px"
        @click="toggle"
      >
        <v-card-title class="pa-2">
          <v-spacer></v-spacer>
          <v-menu bottom left v-if="enabledFarm">
            <template #activator="{ on }">
              <v-btn dark icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <modal-programa
                v-if="hasPermission($p.planning.edit) && enabledFarm"
                :programa="program"
                :cultivar="cultivar"
                @salva="salvarPrograma"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-title>
                      <v-icon>mdi-pencil</v-icon>&nbsp;{{ $t('editar') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </modal-programa>

              <modal-replicacao
                :programa="program"
                :cultivar="cultivar"
                @clonou="clonarPrograma"
                v-if="hasPermission($p.planning.create) && enabledFarm"
              >
                <template #activator="{ on }">
                  <v-list-item v-on="on">
                    <v-list-item-title>
                      <v-icon>mdi-content-copy</v-icon>&nbsp;
                      {{ $t('copiar_para') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </modal-replicacao>

              <v-list-item :disabled="cloningProcess" @click="duplicateProgram">
                <v-list-item-title>
                  <v-icon>mdi-content-duplicate</v-icon> &nbsp;
                  {{ $t('programa.duplicate.label') }}</v-list-item-title
                >
              </v-list-item>

              <v-list-item :to="comparisonRoute">
                <v-list-item-title>
                  <v-icon>mdi-compare-horizontal</v-icon> &nbsp;
                  {{ $t('Comparison.title') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                :href="`#/reports/programa?id=${program.id}`"
                target="_blank"
              >
                <v-list-item-title
                  ><v-icon>mdi-printer</v-icon> &nbsp;
                  {{ $t('print') }}</v-list-item-title
                >
              </v-list-item>

              <v-divider
                v-if="hasPermission($p.planning.delete) && enabledFarm"
              ></v-divider>

              <v-list-item
                v-if="hasPermission($p.planning.delete) && enabledFarm"
                @click="excluirPrograma"
              >
                <v-list-item-title class="red--text"
                  ><v-icon color="red">mdi-delete</v-icon> &nbsp;
                  {{ $t('excluir') }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>

        <v-card-text
          class="float-center text-center d-block white--text pa-2 mt-n12"
        >
          <div class="title">{{ program.nome }}</div>

          <div class="text--secundary">
            {{ $t('semeadura_em', [program.data_prevista.formatDate()]) }}
          </div>
          <div class="d-flex justify-center align-end">
            <v-chip
              small
              class="ma-2 caption white--text"
              color="red darken-1"
              v-if="program.severidade_id == 1"
            >
              <v-icon left small>mdi-weather-rainy</v-icon>
              {{ $t('severidade.alta') }}
            </v-chip>
            <v-chip
              small
              class="ma-2 caption"
              color="orange darken-1"
              v-else-if="program.severidade_id == 2"
            >
              <v-icon left small>mdi-weather-rainy</v-icon>
              {{ $t('severidade.media') }}
            </v-chip>
            <v-chip
              small
              class="ma-2 caption"
              color="yellow darken-1"
              v-else-if="program.severidade_id == 3"
            >
              <v-icon left small>mdi-weather-rainy</v-icon>
              {{ $t('severidade.baixa') }}
            </v-chip>
            <v-chip
              v-if="program.white_mold_enabled"
              color="#305082"
              x-small
              class="spraying-list__white-mold-label ml-3"
              >+ {{ $t('spraying.specific_white_mold_label') }}</v-chip
            >
          </div>
          <div class="text--secundary" v-if="program.sugestao_pulverizacoes">
            <span class="caption">
              {{ $t('pulverizacao.sugestao') }}:
              {{ program.sugestao_pulverizacoes }}
            </span>
          </div>

          <div class="text--secundary">
            <span class="title font-weight-bold pr-2">{{
              $currency(custoTotal())
            }}</span>
            <span class="body-2"
              >{{ custoTotalSc }}
              {{ $unitMeasures['weight'][$currentLanguage()] }}</span
            >
          </div>
        </v-card-text>
      </v-card>

      <div
        v-for="(planejamento, idx) in program.planejamentos"
        :key="planejamento.id"
      >
        <v-card
          tile
          class="grey darken-3 white--text mb-3 text-center d-sm-none"
          elevation="0"
        >
          <v-card-text
            class="float-center text-center white--text pa-2"
            style="height: 100%"
          >
            <div class="title">
              {{ idx + 1 }}ª {{ $t('aplicacao.aplicacao') }}
            </div>
          </v-card-text>
        </v-card>

        <v-card
          class="grey lighten-3 center white--text"
          height="200px"
          @click="toggle"
        >
          <v-card-title class="pa-2 blue-grey">
            <div
              v-if="planejamento.data_aplicacao_planejada"
              class="body-2 ml-2"
            >
              {{ aplicacaoDataRange(planejamento) }}
            </div>

            <v-spacer></v-spacer>

            <v-menu v-if="showDefensiveMenu && enabledFarm" bottom left>
              <template #activator="{ on }">
                <v-btn dark icon small v-on="on">
                  <v-icon small color="white">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <spraying-modal
                  v-if="hasPermission($p.defensive.edit) && enabledFarm"
                  :aplicacao="planejamento.aplicacao"
                  :planning-program="program"
                  @alterou="salvarPlanejamento"
                >
                  <template #activator="{ on }">
                    <v-list-item v-on="on">
                      <v-list-item-title
                        ><v-icon>mdi-pencil</v-icon> &nbsp;
                        {{ $t('editar') }}</v-list-item-title
                      >
                    </v-list-item>
                  </template>
                </spraying-modal>

                <v-divider
                  v-if="canDeleteSpray(idx) && enabledFarm"
                ></v-divider>
                <v-list-item
                  v-if="canDeleteSpray(idx)"
                  @click="excluirPlanejamento(planejamento)"
                >
                  <v-list-item-title class="red--text"
                    ><v-icon color="red">mdi-delete</v-icon> &nbsp;
                    {{ $t('excluir') }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>

          <v-card-text
            class="float-center text-center d-block gray--text text--darken-2"
          >
            <v-row align="center" class="pl-2 pr-6">
              <v-col
                v-for="produto in planejamento.planejamento_defensivos.slice(
                  0,
                  3
                )"
                :key="produto.id"
                cols="6"
              >
                <div>
                  <div class="font-weight-bold" v-if="produto.defensivo">
                    {{ produto.defensivo.nome }}
                  </div>
                  <div class="caption" v-if="produto.defensivo">
                    {{ parseFloat(produto.dose).toFixedNotRounded(2) }}
                    {{ produto.defensivo.unidade_medida.replace('_', ' ') }}
                  </div>
                  <div class="font-weight-bold" v-else>
                    {{ $t('desconhecido') }}
                  </div>
                </div>
              </v-col>

              <!-- Se houver mais de 4 produtos, mostra quantos produtos não couberam na tela
                    e coloca tooltip para mostrar eles -->
              <v-col
                v-if="planejamento.planejamento_defensivos.length > 4"
                cols="6"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <div v-on="on" class="title font-weight-bold">
                      + {{ planejamento.planejamento_defensivos.length - 3 }}
                    </div>
                  </template>
                  <div
                    v-for="produto in planejamento.planejamento_defensivos.slice(
                      3
                    )"
                    :key="'tooltip_' + produto.id"
                  >
                    {{ produto.defensivo.nome }}
                    ( {{ parseFloat(produto.dose).toFixedNotRounded(2)
                    }}{{ produto.defensivo.unidade_medida.replace('_', ' ') }} )
                  </div>
                </v-tooltip>
              </v-col>

              <!-- Senão, mostra o quarto produto -->
              <v-col
                v-else-if="planejamento.planejamento_defensivos.length === 4"
                cols="6"
              >
                <div class="font-weight-bold">
                  {{ planejamento.planejamento_defensivos[3].defensivo.nome }}
                </div>
                <div class="caption">
                  {{
                    parseFloat(
                      planejamento.planejamento_defensivos[3].dose
                    ).toFixedNotRounded(2)
                  }}
                  {{
                    planejamento.planejamento_defensivos[3].defensivo.unidade_medida.replace(
                      '_',
                      ' '
                    )
                  }}
                </div>
              </v-col>
            </v-row>
            <v-chip
              v-if="specificWhiteMoldSpraying(planejamento)"
              color="#305082"
              x-small
              class="spraying-list__white-mold-label -absolute"
              >{{ $t('spraying.specific_white_mold_label') }}</v-chip
            >
          </v-card-text>
        </v-card>

        <v-row class="mt-4" justify="center" align="center" no-gutters>
          <v-col cols="2"></v-col>
          <v-col cols="4" class="body-1 font-weight-bold text-right pr-1">{{
            $currency(custoPlanejamento(planejamento))
          }}</v-col>
          <v-col cols="4" class="body-2 text-left"
            >{{ custoPlanejamentoSc(planejamento)
            }}{{ $unitMeasures['weight'][$currentLanguage()] }}</v-col
          >
          <v-col cols="2">
            <v-tooltip
              top
              max-width="300"
              v-if="semRegistro(planejamento.eficacias) && isBrazil"
            >
              <template #activator="{ on }">
                <div v-on="on">
                  <v-icon dense class="red--text">mdi-alert-circle</v-icon>
                </div>
              </template>

              <div>
                {{ $t('eficacia.sem_registro_ajuda') }}
              </div>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          no-gutters
          v-if="planejamento.eficacias"
          style="height: 270px"
        >
          <v-col>
            <efficacies
              :spraying="planejamento"
              :mobile="$vuetify.breakpoint.xs"
              is-planning
              hide-labels
            />
          </v-col>
        </v-row>
      </div>

      <div style="width: 260px; white-space: initial !important">
        <total-sprayings-info :program="program"></total-sprayings-info>
      </div>

      <v-row
        v-if="allowNovaAplicacao"
        :key="`novoPlanejamento_${program.planejamentos.length + 1}`"
        justify="center"
      >
        <v-col class="text-center">
          <spraying-modal
            v-if="hasPermission($p.defensive.create) && enabledFarm"
            :aplicacao="novoPlanejamentoIndex"
            :planning-program="program"
            @alterou="salvarPlanejamento"
          >
            <template #activator="{ on }">
              <v-btn
                class="mx-2"
                fab
                dark
                color="green darken-1"
                height="50px"
                width="50px"
                v-on="on"
                @click="handleButtonClick"
              >
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
              <p class="text--secondary caption mt-1">
                {{ $t('aplicacao.adicionar_aplicacao') }}
              </p>
            </template>
          </spraying-modal>
        </v-col>
      </v-row>
    </div>
  </v-slide-item>
</template>

<style scoped lang="scss">
.v-card__text {
  padding: 0px;
}
.v-alert {
  padding: 2px;
}
.spraying-list__white-mold-label {
  text-transform: uppercase;
  font-weight: 600;
  bottom: 12px;
  right: 12px;
  color: white;

  &.-absolute {
    position: absolute;
  }
}
</style>
