<template>
  <df-page-header
    class="disease-header"
    :title="$t('menu.diseases')"
    :subtitle="$t('menu.planejamento')"
  >
    <template #actions>
      <selector-seasons-crops class="header-actions"></selector-seasons-crops>
    </template>
  </df-page-header>
</template>

<script>
import SelectorSeasonsCrops from '@/components/SeasonsCrops/SelectorSeasonsCrops.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'

export default {
  name: 'DiseaseHeader',

  components: {
    SelectorSeasonsCrops,
    DfPageHeader,
  },
}
</script>

<style lang="scss" scoped>
.disease-header {
  .header-actions {
    max-width: 200px;
  }
}
</style>
